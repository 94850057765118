import React from "react";
import { EventsAndActivites } from "../components/EventsAndActivities/EventsAndActivites";

import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";

const EventAndActivitiesPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Events &amp; Activities - ACSS UPLB" />
      <EventsAndActivites />
    </Layout>
  );
};

export default EventAndActivitiesPage;
