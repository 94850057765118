import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { EventCardItem } from "./EventCardItem";
import "./EventsAndActivites.scss";

/**
 * EventsAndActivites.tsx
 *
 * Component for the contents of Events and Activities page.
 *
 */
export const EventsAndActivites: React.FC = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___title] }
        filter: { frontmatter: { type: { eq: "event" } } }
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              heroImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { edges } = allMarkdownRemark;
  return (
    <main id="events-and-activities">
      <h1 className="check-out-our">
        Check out our{" "}
        <span className="events-and-activities-name">
          Events &amp; Activities
        </span>
      </h1>
      <section id="event-items-grid-container">
        {edges.map((eventItem: any) => (
          <EventCardItem
            key={eventItem.node.id}
            link={eventItem.node.frontmatter.slug}
            title={eventItem.node.frontmatter.title}
            image={{
              alt: eventItem.node.frontmatter.title,
              src: eventItem.node.frontmatter.heroImage.childImageSharp.fluid,
            }}
          />
        ))}
      </section>
    </main>
  );
};
