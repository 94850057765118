import React from "react";
import Img, { FluidObject } from "gatsby-image";
import "./EventCardItem.scss";
import { Link } from "gatsby";

/**
 * EventsAndActivites.tsx
 *
 * Component for the individual card item in the Events and Activities page main list.
 *
 */

/**
 * EventItemProps
 * Component Props
 */
interface EventItemProps {
  link: string;
  title: string;
  image: {
    alt: string;
    src: FluidObject;
  };
}

/**
 * EventCardItem
 * Component Proper
 */
export const EventCardItem: React.FC<EventItemProps> = ({
  link,
  title,
  image,
}) => {
  return (
    <Link to={link} className="event-card-item">
      <div className="event-main-image">
        <Img fluid={image.src} alt={image.alt} />
      </div>
      <span className="event-title">{title}</span>
    </Link>
  );
};
